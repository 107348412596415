.App {
  overflow: hidden;
}

.App-header {
  background-color: #9c0000;
  width: 100vw;
  height: 100vh;
}

.isFerie {
  background-color: #009c1f !important;
}

#c {
}

#block {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255,255,255,0) 30%, rgba(0,0,0,0.7185574058725053) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

#text {
  color: white;
  text-align: center;
  /* background: linear-gradient(to bottom, transparent 0%, black 100%); */
}